import React, { useRef } from "react"
import styled from "styled-components"

import useWebiste from "../states/context/websiteContext"
import useOnScreen from "../utils/hooks/useOnScreen"

import Layout from "../components/layout"
import SectionTitle from "../components/titles/sectiontitle"
import BlueButton from "../components/buttons/blueButton"
import SEO from "../components/seo"
import { Language } from "../states/context/websiteReducer"
import { servicesData, servicesDataFr } from "../data/servicesData"

const ServicesPage = () => {
  const { language } = useWebiste()
  const isEnglish = language == Language.en
  const ref = useRef<HTMLDivElement | null>(null);
  const isVisible = useOnScreen(ref);

  return (
    <Layout>
      <SEO title="Services" lang={language} />
      <Wrapper ref={ref} className={isVisible ? "animate__animated animate__fadeIn animate__delay-0.5s" : undefined}>
        <SectionTitle title={isEnglish ? "What are our services?" : "Quels sont nos services ?"} />
        <DescriptionWrapper>
          <div>
            {
              (isEnglish ? servicesData : servicesDataFr).map((data, index) => (
                <div key={index}>
                  <Description>
                    {data.description1}
                  </Description>
                  <BulletList>
                    <li>{data.service1}</li>
                    <li>{data.service2}</li>
                    <li>{data.service3}</li>
                    <li>{data.service4}</li>
                  </BulletList>
                  <Description>{data.description2}</Description>
                </div>
              ))
            }
            {isEnglish ? <Description>
              Visit our <a href="/contact">Contact Us</a> page to fill in a form
              or give us a call at{" "}
              <a href="tel:514-432-7446">+1-514-432-7446</a> !
            </Description> : <Description>
              Visitez notre page <a href="/contact">Contactez-nous</a> pour remplir un formulaire ou appelez-nous au{" "}
              <a href="tel:514-432-7446">+1-514-432-7446</a> !
            </Description>}
          </div>
          <Image src="/images/directionalDrillingimage.png" />
        </DescriptionWrapper>

        <QuoteWrapper>
          <Title>{isEnglish ? "To get a quote, contact us" : "Pour un devis, contactez-nous"}</Title>
          <Underline />
          <BlueButton
            text={isEnglish ? "Contact Us" : "Contactez-nous"}
            onClick={() => (location.href = "/contact")}
          />
        </QuoteWrapper>
      </Wrapper>
    </Layout>
  )
}

export default ServicesPage

const Wrapper = styled.div`
  padding: 100px 0;
  max-width: 1140px;
  margin: 0 auto;

  @media (max-width: 1220px) {
    padding: 40px;
  }
`

const DescriptionWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: 40px;
  justify-items: center;

  @media (max-width: 1220px) {
    grid-template-columns: auto;
    justify-items: start;
  }
`

const Description = styled.p`
  max-width: 500px;
  line-height: 50px;

  letter-spacing: 0.1em;

  a {
    color: black;
    font-weight: bold;
    text-decoration: underline;
  }
`

const BulletList = styled.ul`
  font-family: "Heebo", sans-serif;
  line-height: 50px;

  letter-spacing: 0.1em;
`

const Image = styled.img`
  @media (max-width: 580px) {
    width: 300px;
  }

  @media (max-width: 375px) {
    width: 250px;
  }
`

const QuoteWrapper = styled.div`
  display: grid;
  justify-items: center;
  padding: 100px 0;
  gap: 20px;

  button {
    margin-top: 20px;
  }

  @media (max-width: 1220px) {
    justify-items: start;
  }
`

const Title = styled.h2`
  font-weight: bold;
  font-size: 35px;
  line-height: 50px;

  letter-spacing: 0.1em;
  text-transform: uppercase;
`

const Underline = styled.div`
  width: 65px;
  height: 10px;

  background: #005085;
`
