export const servicesData = [
  {
    title: "What are our services?",
    description1: "We offer different types of drilling, for all your directional drilling needs:",
    service1: "Shots lower than 5 inches",
    service2: "Shots between 5 to 7 inches, using a reamer",
    service3: "Shots between 7 to 9 inches, using a reamer",
    service4: "Shots higher than 9 inches",
    description2: "Contact us for a quote!",

  }
]

export const servicesDataFr = [
  {
    title: "Quels sont nos services ?",
    description1: "Nous offrons différents types de forage, pour tous vos besoins en matière de forage directionnel :",
    service1: "Tirs de moins de 5 pouces",
    service2: "Tirs entre 5 et 7 pouces, à l'aide d'un alésoir",
    service3: "Tirs entre 7 et 9 pouces, à l'aide d'un alésoir",
    service4: "Tirs de plus de 9 pouces",
    description2: "Contactez-nous pour un devis!"
  }
]